import React, { useContext } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faHome,
  faClipboardList,
  faUsers,
  faHandshake,
} from '@fortawesome/free-solid-svg-icons';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import {
  getTranslation,
  MENU_ABOUT,
  MENU_ACTIVITIES,
  MENU_PARTNERS,
  MENU_SCHEDULE,
} from '../translations';
import { LanguageContext } from '../LangaugeContext';

const MenuWrapper = styled.div`
  height: 70px;
  background: #e73122;
  position: fixed;
  bottom: 0;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 16px;
`;

const MenuItem = styled(Link)`
  color: #fff;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 14px;
  text-decoration: none;
`;

const IconWrapper = styled.div`
  margin-bottom: 8px;
`;

export const Menu = () => {
  const { language } = useContext(LanguageContext);

  return (
    <MenuWrapper>
      <MenuItem to={'/about'}>
        <IconWrapper>
          <FontAwesomeIcon icon={faHome} size="2x" />
        </IconWrapper>
        <span>{getTranslation(MENU_ABOUT, language)}</span>
      </MenuItem>
      <MenuItem to={'/schedule'}>
        <IconWrapper>
          <FontAwesomeIcon icon={faClipboardList} size="2x" />
        </IconWrapper>
        <span>{getTranslation(MENU_SCHEDULE, language)}</span>
      </MenuItem>
      <MenuItem to={'/activities'}>
        <IconWrapper>
          <FontAwesomeIcon icon={faUsers} size="2x" />
        </IconWrapper>
        <span>{getTranslation(MENU_ACTIVITIES, language)}</span>
      </MenuItem>
      <MenuItem to={'/partners'}>
        <IconWrapper>
          <FontAwesomeIcon icon={faHandshake} size="2x" />
        </IconWrapper>
        <span>{getTranslation(MENU_PARTNERS, language)}</span>
      </MenuItem>
    </MenuWrapper>
  );
};
