import { useLocation } from 'react-router-dom';
import { useEffect } from 'react';

export const useScrollToTopOnLocationChange = () => {
  const location = useLocation();

  useEffect(
    () => {
      window.scrollTo(0, 0);
    },
    [location]
  );
};
