import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { Redirect } from 'react-router-dom';

export const SplashScreenWrapper = styled.div`
  background: url('assets/splash-screen.png') no-repeat center center;
  background-size: contain;
  width: 100%;
  height: 100%;
  position: fixed;
`;

export const SplashScreen = () => {
  const [timeExpired, setTimeExpired] = useState(false);

  useEffect(() => {
    setTimeout(() => {
      setTimeExpired(true);
    }, 1500);
  }, []);

  return !timeExpired ? <SplashScreenWrapper /> : <Redirect to="/about" />;
};
