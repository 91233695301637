import React, { useContext, useEffect, useState } from 'react';
import { Redirect, useHistory, useParams } from 'react-router-dom';
import { Heading } from '../components/Heading';
import styled from 'styled-components';
import { Paragraph } from '../components/Paragraph';
import { Button } from '../components/Button';
import { fetchSpeaker } from '../services';
import { LanguageContext } from '../LangaugeContext';
import { getTranslation, TRANSLATION_BACK } from '../translations';

const SpeakerImage = styled.img`
  border-radius: 50%;
  width: 300px;
  height: 300px;
  justify-content: center;
  margin: 16px auto;
  margin-top: 20px;
  display: block;
  filter: grayscale(100%);
  z-index: -1;
  position: relative;
`;

const SpeakerJob = styled.div`
  margin: 16px;
  text-align: center;
  font-weight: bold;
`;

export const SpeakerScreen = () => {
  const { id } = useParams();
  const history = useHistory();

  const { language } = useContext(LanguageContext);

  const [speaker, setSpeaker] = useState(null as any);

  useEffect(
    () => {
      window.scrollTo(0, 0);

      setSpeaker(fetchSpeaker(parseInt(id!, 10)));
    },
    [setSpeaker, id]
  );

  return (
    <>
      {speaker && (
        <>
          <Heading>
            {speaker.name[language] ? speaker.name[language] : speaker.name}
          </Heading>
          <SpeakerImage src={speaker.img} />
          <SpeakerJob>
            {speaker.job[language] ? speaker.job[language] : speaker.job}
          </SpeakerJob>
          {speaker.text && speaker.text[language] && (
            <Paragraph
              dangerouslySetInnerHTML={{ __html: speaker.text[language] }}
            />
          )}
          <Button
            onClick={() => {
              history.goBack();
            }}
          >
            {getTranslation(TRANSLATION_BACK, language)}
          </Button>
        </>
      )}

      {speaker === undefined && <Redirect to={'/'} />}
    </>
  );
};
