import React, { useEffect } from 'react';
import { Header } from './Header';
import { Menu } from './Menu';
import styled from 'styled-components';
import { useLocation } from 'react-router-dom';

const ContentWrapper = styled.div`
  padding: 16px;
  padding-top: 70px;
  margin-bottom: 70px;
`;

export const ScreenLayout: React.FC = ({ children }) => {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return pathname !== '/' ? (
    <>
      <Header />
      <ContentWrapper>{children}</ContentWrapper>
      <Menu />
    </>
  ) : (
    <>{children}</>
  );
};
