import { Language } from './types/language';

export const TRANSLATION_BACK = 'back';
export const TRANSLATION_GENERAL_PARTNER = 'generalPartner';
export const TRANSLATION_MAIN_PARTNER = 'mainPartner';
export const TRANSLATION_PARTNER = 'partner';
export const TRANSLATION_MEDIA_PARTNER = 'mediaPartner';
export const TRANSLATION_VOUCHER_PARTNER = 'voucherPartner';
export const TRANSLATION_PRODUCT_PARTNER = 'productPartner';
export const TRANSLATION_SUPPORT_PARTNER = 'supportPartner';
export const TRANSLATION_ACCOMPANYING_PROGRAM = 'accompanyingProgram';
export const TRANSLATION_ACCOMPANYING_PROGRAM_TEXT = 'accompanyingProgramText';
export const TRANSLATION_COOKIES_INFO = 'cookiesInfo';

export const MENU_SCHEDULE = 'schedule';
export const MENU_ABOUT = 'about';
export const MENU_PARTNERS = 'partners';
export const MENU_ACTIVITIES = 'activities';

const translations: {
  cs: {
    [key: string]: string;
  };
  en: {
    [key: string]: string;
  };
} = {
  cs: {
    [TRANSLATION_BACK]: 'Zpět',
    [TRANSLATION_GENERAL_PARTNER]: 'Generální partner',
    [TRANSLATION_MAIN_PARTNER]: 'Hlavní partneři',
    [TRANSLATION_PARTNER]: 'Partneři',
    [TRANSLATION_MEDIA_PARTNER]: 'Mediální partneři',
    [TRANSLATION_VOUCHER_PARTNER]: 'Slevový voucher',
    [TRANSLATION_PRODUCT_PARTNER]: 'Produktoví partneři',
    [TRANSLATION_SUPPORT_PARTNER]: 'Podporující firmy',
    [MENU_ABOUT]: 'TEDx',
    [MENU_SCHEDULE]: 'PROGRAM',
    [MENU_PARTNERS]: 'PARTNEŘI',
    [MENU_ACTIVITIES]: 'AKTIVITY',
    [TRANSLATION_ACCOMPANYING_PROGRAM]: 'Doprovodný program',
    [TRANSLATION_ACCOMPANYING_PROGRAM_TEXT]:
      'Prozkoumejte prostor Tržnice a Ateliéru DEPO2015',
    [TRANSLATION_COOKIES_INFO]: 'Informace o cookies',
  },
  en: {
    [TRANSLATION_BACK]: 'Back',
    [TRANSLATION_GENERAL_PARTNER]: 'General partner',
    [TRANSLATION_MAIN_PARTNER]: 'Main partners',
    [TRANSLATION_PARTNER]: 'Partners',
    [TRANSLATION_MEDIA_PARTNER]: 'Medial partners',
    [TRANSLATION_VOUCHER_PARTNER]: 'Slevový voucher',
    [TRANSLATION_PRODUCT_PARTNER]: 'Product partners',
    [TRANSLATION_SUPPORT_PARTNER]: 'Supporting partner',
    [MENU_ABOUT]: 'TEDx',
    [MENU_SCHEDULE]: 'SCHEDULE',
    [MENU_PARTNERS]: 'PARTNERS',
    [MENU_ACTIVITIES]: 'ACTIVITIES',
    [TRANSLATION_ACCOMPANYING_PROGRAM]: 'Accompanying program',
    [TRANSLATION_ACCOMPANYING_PROGRAM_TEXT]:
      'Explore every corner of the Karlín Forum! To help with orientation, there will be signs displaying a circle and floor number that will lead you to your desired activity and they include a simple description of that activity.',
    [TRANSLATION_COOKIES_INFO]: 'Cookies information',
  },
};

export const getTranslation = (
  translation: string,
  language: Language
): string => translations[language][translation];
