import { speakers } from './data';

export const fetchSpeaker = (id: number) => {
  let speaker = undefined;

  speakers.forEach(speakerBlock => {
    const sp = speakerBlock.speakers.filter(speaker => speaker.id === id);

    if (sp.length === 1) {
      speaker = sp[0];
    }
  });

  return speaker;
};
