import React, { useContext } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { LanguageContext } from '../LangaugeContext';
import { notFoundPageContent } from '../data';
import { Heading } from '../components/Heading';
import { getTranslation, TRANSLATION_BACK } from '../translations';
import { Button } from '../components/Button';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { useScrollToTopOnLocationChange } from '../hooks';

const BackLink = styled(Link)`
  text-decoration: none;
`;

export const NotFoundScreen: React.FC<RouteComponentProps> = () => {
  useScrollToTopOnLocationChange();

  const { language } = useContext(LanguageContext);

  return (
    <>
      <Heading>{notFoundPageContent[language]}</Heading>

      <BackLink to={'/'}>
        <Button>{getTranslation(TRANSLATION_BACK, language)}</Button>
      </BackLink>
    </>
  );
};
