import React, { useContext } from 'react';
import { partners } from '../data';
import { Heading } from '../components/Heading';
import { LanguageContext } from '../LangaugeContext';
import { getTranslation } from '../translations';
import styled from 'styled-components';
import { RouteComponentProps } from 'react-router-dom';
import { useScrollToTopOnLocationChange } from '../hooks';

const PartnerImage = styled.img<{ fullWidth?: boolean }>`
  ${props => (props.fullWidth ? 'max-width: 100%' : 'width: 250px')}
  margin: 60px auto;
  display: block;
`;

export const PartnersScreen: React.FC<RouteComponentProps> = () => {
  useScrollToTopOnLocationChange();

  const { language } = useContext(LanguageContext);

  return (
    <>
      {partners.map((partner, i) => (
        <div key={i}>
          <Heading>{getTranslation(partner.title, language)}</Heading>

          {partner.logos.map((logo, j) => (
            <a href={logo.link} key={j}>
              <PartnerImage src={logo.img} fullWidth={logo.fullWidth} />
            </a>
          ))}
        </div>
      ))}
    </>
  );
};
