import React, { useContext } from 'react';
import styled from 'styled-components';
import { LanguageContext } from '../LangaugeContext';
import { Link } from 'react-router-dom';
import { Language } from '../types/language';

const HeaderWrapper = styled.div`
  padding: 16px;
  height: 70px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: fixed;
  background: #fff;
  width: 100%;
`;

const Logo = styled.img`
  width: 170px;
`;

const FlagsWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const FlagImg = styled.img`
  width: 48px;
  margin: 0 8px;
`;

const languages: Language[] = ['cs'];

export const Header = () => {
  const languageContext = useContext(LanguageContext);

  return (
    <HeaderWrapper>
      <Link to="/about">
        <Logo src="/assets/logo.png" alt="" />
      </Link>
      {languages.length > 1 && (
        <FlagsWrapper>
          {languages.map((language, i) => (
            <FlagImg
              key={i}
              src={`/assets/flags/${language}.png`}
              alt=""
              onClick={() => languageContext.setLanguage(language)}
            />
          ))}
        </FlagsWrapper>
      )}
    </HeaderWrapper>
  );
};
