import React, { useState } from 'react';
import { createGlobalStyle } from 'styled-components';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { SplashScreen } from './screens/SplashScreen';
import { AboutScreen } from './screens/AboutScreen';
import { ScheduleScreen } from './screens/ScheduleScreen';
import { PartnersScreen } from './screens/PartnersScreen';
import { ActivitiesScreen } from './screens/ActitivesScreen';
import { LanguageContext } from './LangaugeContext';
import { SpeakerScreen } from './screens/SpeakerScreen';
import { NotFoundScreen } from './screens/NotFoundScreen';
import { ScreenLayout } from './components/ScreenLayout';
import { CookiesScreen } from './screens/CookiesScreen';

const DEFAULT_LANGUAGE = 'cs';

const GlobalStyle = createGlobalStyle`
  * {
    box-sizing: border-box;
  }

  body {
    margin: 0;
    font-family: 'Open Sans', sans-serif;
  }
  
  a {
    color: #e73122;
  }
`;

const App: React.FC = () => {
  const [language, setLanguage] = useState(DEFAULT_LANGUAGE);

  return (
    <LanguageContext.Provider value={{ language, setLanguage } as any}>
      <Router>
        <ScreenLayout>
          <Switch>
            <Route path="/" component={SplashScreen} exact={true} />
            <Route path="/about" component={AboutScreen} />
            <Route path="/schedule" component={ScheduleScreen} />
            <Route path="/partners" component={PartnersScreen} />
            <Route path="/activities" component={ActivitiesScreen} />
            <Route path="/speaker/:id" component={SpeakerScreen} />
            <Route path="/cookies" component={CookiesScreen} />
            <Route component={NotFoundScreen} />
          </Switch>
        </ScreenLayout>
      </Router>
      <GlobalStyle />
    </LanguageContext.Provider>
  );
};

export default App;
