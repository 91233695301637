import React, { useContext } from 'react';
import { Link, RouteComponentProps } from 'react-router-dom';
import { LanguageContext } from '../LangaugeContext';
import { aboutUsContent } from '../data';
import { Heading } from '../components/Heading';
import { Paragraph } from '../components/Paragraph';
import { useScrollToTopOnLocationChange } from '../hooks';
import { getTranslation, TRANSLATION_COOKIES_INFO } from '../translations';
import { Button } from '../components/Button';
import styled from 'styled-components';

const CookiesLink = styled(Link)`
  text-decoration: none;
`;

export const AboutScreen: React.FC<RouteComponentProps> = () => {
  useScrollToTopOnLocationChange();

  const { language } = useContext(LanguageContext);

  return (
    <>
      {aboutUsContent[language].map((section, i) => (
        <div key={i}>
          <Heading>{section.header}</Heading>

          {section.content.map((paragraph: string, j) => (
            <div style={{ marginBottom: 15 }} key={j}>
              <Paragraph>
                <span dangerouslySetInnerHTML={{ __html: paragraph }} />
              </Paragraph>
            </div>
          ))}
        </div>
      ))}

      <CookiesLink to="/cookies">
        <Button>{getTranslation(TRANSLATION_COOKIES_INFO, language)}</Button>
      </CookiesLink>
    </>
  );
};
