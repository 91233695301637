import React, { useContext } from 'react';
import { LanguageContext } from '../LangaugeContext';
import { RouteComponentProps } from 'react-router-dom';
import { Heading } from '../components/Heading';
import { Paragraph } from '../components/Paragraph';
import { sideActivities } from '../data';
import { useScrollToTopOnLocationChange } from '../hooks';
import styled from 'styled-components';
import {
  getTranslation,
  TRANSLATION_ACCOMPANYING_PROGRAM,
  TRANSLATION_ACCOMPANYING_PROGRAM_TEXT,
} from '../translations';

const ActivityLocation = styled.div`
  font-weight: bold;
  color: grey;
`;

export const ActivitiesScreen: React.FC<RouteComponentProps> = () => {
  useScrollToTopOnLocationChange();

  const { language } = useContext(LanguageContext);

  return (
    <>
      <Heading>
        {getTranslation(TRANSLATION_ACCOMPANYING_PROGRAM, language)}
      </Heading>

      <Paragraph>
        {getTranslation(TRANSLATION_ACCOMPANYING_PROGRAM_TEXT, language)}
      </Paragraph>

      {sideActivities.map((activity, i) => (
        <div key={i}>
          <Heading>{activity.title[language]}</Heading>

          <ActivityLocation>{activity.location[language]}</ActivityLocation>

          <Paragraph dangerouslySetInnerHTML={{ __html: activity.text[language] }} />
        </div>
      ))}
    </>
  );
};
