import React, { useContext } from 'react';
import { RouteComponentProps, useHistory } from 'react-router-dom';
import { LanguageContext } from '../LangaugeContext';
import { cookiesContent } from '../data';
import { Heading } from '../components/Heading';
import { Paragraph } from '../components/Paragraph';
import { useScrollToTopOnLocationChange } from '../hooks';
import { Button } from '../components/Button';
import { getTranslation, TRANSLATION_BACK } from '../translations';

export const CookiesScreen: React.FC<RouteComponentProps> = () => {
  useScrollToTopOnLocationChange();

  const { language } = useContext(LanguageContext);
  const history = useHistory();

  return (
    <>
      {cookiesContent[language].map((section, i) => (
        <div key={i}>
          <Heading>{section.header}</Heading>

          {section.content.map((paragraph: string, j) => (
            <div style={{ marginBottom: 15 }} key={j}>
              <Paragraph>
                <span dangerouslySetInnerHTML={{ __html: paragraph }} />
              </Paragraph>
            </div>
          ))}
        </div>
      ))}

      <Button
        onClick={() => {
          history.goBack();
        }}
      >
        {getTranslation(TRANSLATION_BACK, language)}
      </Button>
    </>
  );
};
