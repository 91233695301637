import React, { useContext } from 'react';
import { Heading } from '../components/Heading';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { speakers } from '../data';
import { LanguageContext } from '../LangaugeContext';
import { TYPE_SPEAKER } from '../constants';

const SpeakerLink = styled(Link)`
  display: flex;
  color: #000;
  text-decoration: none;
  align-items: center;
  margin-bottom: 26px;
`;

const SpeakerImage = styled.img<{ greyscale?: boolean }>`
  border-radius: 50%;
  width: 100px;
  height: 100px;
  flex-shrink: 0;
  margin-right: 16px;
  ${props => props.greyscale && `filter: grayscale(100%); z-index: -1;`}
`;

const SpeakerDescription = styled.div``;

const SpeakerName = styled.div`
  font-weight: bold;
  font-size: 25px;
  margin-bottom: 8px;
`;

const SpeakerJob = styled.div``;

export const ScheduleScreen = () => {
  const { language } = useContext(LanguageContext);

  return (
    <>
      {speakers.map((speakerBlock, i) => (
        <div key={i}>
          <Heading fontSize={'smaller'}>{speakerBlock.time[language]}</Heading>
          {speakerBlock.speakers.map((speaker, j) => (
            <SpeakerLink
              to={
                speaker.type === TYPE_SPEAKER
                  ? `speaker/${speaker.id}`
                  : 'activities'
              }
              key={j}
            >
              {/*
 // @ts-ignore */}
              <SpeakerImage
                src={speaker.img}
                greyscale={speaker.type === TYPE_SPEAKER}
                loading="lazy"
              />
              <SpeakerDescription>
                <SpeakerName>
                  {typeof speaker.name === 'object'
                    ? speaker.name[language]
                    : speaker.name}
                </SpeakerName>
                <SpeakerJob>
                  {speaker.job[language] ? speaker.job[language] : speaker.job}
                </SpeakerJob>
              </SpeakerDescription>
            </SpeakerLink>
          ))}

          {speakerBlock.additionalInformation &&
            speakerBlock.additionalInformation[language] && (
              <span
                dangerouslySetInnerHTML={{
                  __html: speakerBlock.additionalInformation[language],
                }}
              />
            )}
        </div>
      ))}
    </>
  );
};
