import styled from 'styled-components';

interface Props {
  fontSize?: 'standard' | 'smaller';
}

export const Heading = styled.div<Props>`
  border-bottom: 3px solid #e73122;
  font-size: ${props =>
    !props.fontSize || props.fontSize === 'standard' ? 25 : 21}px;
  padding-bottom: 10px;
  margin-bottom: 10px;
  font-weight: bold;
`;
