import styled from 'styled-components';

export const Button = styled.div`
  padding: 10px 16px;
  color: #e73122;
  margin: 16px auto;
  font-size: 20px;
  text-transform: uppercase;
  text-align: center;
`;
